<template>
  <section class="home-hero">
    <div class="home-hero-cnt">
      <div class="left">
        <div class="uppercase-sm fbold">Onlysales</div>
        <h1 class="headline-1 fbold">
          Le nouveau site d'emploi des commerciaux B2B.
        </h1>
        <div class="body-lg">
          Connectez-vous sur Onlysales Jobs pour recruter de bons commerciaux B2B dans la Tech, les Services et l'Industrie.
        </div>
        <div class="cta">
          <CtaButton
            label="Publier gratuitement une offre"
            :black="true"
            @click="onJobsLink"
          />
        </div>
      </div>
      <div class="right">
        <img :src="ManHero" alt="Onlysales" @click="onMrClick" ref="mr" />
      </div>
    </div>
  </section>
</template>

<script>
import CtaButton from "front-lib-os/src/components/atoms/CtaButton.vue";
import ManHero from "@/assets/ppl-hero.png";

export default {
  name: "HomeHero",
  components: {
    CtaButton,
  },
  data() {
    return {
      ManHero,
      clicks: 0,
    };
  },
  methods: {
    // onClickHelpMeRecruit() {
    //   window.open(
    //     "https://meetings-eu1.hubspot.com/arnaud-de-onlysales/rv-avec-arnaud"
    //   );
    // },
    onJobsLink() {
      window.open("https://jobs.onlysales.fr");
    },
    onMrClick() {
      this.clicks ++

      if(this.clicks > 0 && this.clicks % 5 == 0) {
        this.$refs.mr.classList.add('spinning')
      } else {
        this.$refs.mr.classList.remove('spinning')
      }
    }
  },
};
</script>

<style lang="scss">
.home-hero {
  color: $neutral900;
  background-color: $primary300;
  padding: 64px 0 96px;
  text-align: left;

  .home-hero-cnt {
    width: 1280px;
    margin: 0 auto;

    .left {
      float: left;
      width: 608px;
      align-items: center;
      padding-top: 100px;

      .headline-1 {
        margin: 16px 0 24px;
      }

      .body-lg {
        margin: 24px auto 40px;
      }
    }

    .right {
      float: right;
      width: 608px;
      margin-left: 64px;

      img {
        height: 600px;

        &.spinning {
          animation: spin 0.5s linear infinite;
        }
      }
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-hero {
    width: 100%;
    padding: 64px 24px;
    text-align: center;

    .home-hero-cnt {
      width: 100%;

      .left {
        padding-top: 0;
        float: none;
        width: 100%;
      }

      .right {
        width: 100%;
        float: none;
        margin: 32px auto 0;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>