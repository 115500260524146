<template>
  <div class="service-item">
    <div class="service-item-cnt">
      <div class="service-item-img">
        <img :src="service.img" :alt="service.title" />
      </div>
      <div class="service-item-title headline-3 fbold">{{ service.title }}</div>
      <div class="service-item-subtitle body-md fsemibold">{{ service.subtitle }}</div>
      <div class="service-item-desc body-md">{{ service.desc }}</div>
      <!-- <div class="service-item-btn">
        <button class="btn btn-primary">En savoir plus</button>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.service-item {
  display: block;
  width: 576px;
  // height: 360px;
  height: 280px;
  margin-top: 24px;

  border-radius: 16px;
  padding: 0 40px 40px 40px;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  background: white;

  text-align: center;

  .service-item-cnt {
    width: 100%;
    text-align: center;

    .service-item-img {
      display: inline-block;
      width: 56px;
      height: 56px;
      padding: 14px;
  
      border-radius: 12px;
      border: 2.33px solid #ffae20;
      background: white;

      position: relative;
      top: -28px;

      img {
        position: relative;
        top: -1px;
        left: -1px;
      }
    }

    .service-item-title {
      margin: 8px 0 8px 0;
    }

    .service-item-subtitle {
      color: $neutral600;
      margin: 0 0 16px 0;
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .service-item {
    width: 100%;
    height: auto;
    padding: 40px;

    .service-item-cnt {
      .service-item-img {
        top: -70px;
      }

      .service-item-title {
        margin: -32px 0 8px 0;

        color: $neutral900;
        text-align: center;

        /* Headline 4/Bold */
        font-family: 'Nunito';
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 133.333% */
      }

      .service-item-subtitle {
        color: var(--Neutral-600, #475467);
        text-align: center;

        /* Body md/SemiBold */
        font-family: 'Open Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }

      .service-item-desc {
        /* Body md/Regular */
        font-family: 'Open Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }
  }
}
</style>