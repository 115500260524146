<template>
  <section class="home-services">
    <div class="home-services-cnt">
      <div class="background-cnt" />
      <div class="items-cnt">
        <ServiceItem
          v-for="service in services"
          :key="service.id"
          :service="service"
        />
      </div>
    </div>
  </section>
</template>

<script>
import ServiceItem from "@/components/atoms/ServiceItem.vue";

export default {
  name: "HomeServices",
  components: {
    ServiceItem,
  },
  data() {
    return {
      services: [
        {
          id: 1,
          img: require("@/assets/icons/user-search.svg"),
          title: "Recrutement",
          subtitle: "Vous avez besoin de recruter des commerciaux qualifiés ?",
          desc: "Nos consultants experts en recrutement chassent et évaluent pour vous les meilleurs profils commerciaux disponibles ou en poste du marché.",
        },
        {
          id: 2,
          img: require("@/assets/icons/book-open.svg"),
          title: "Formation",
          subtitle:
            "Vous pensez que vos commerciaux ont besoin d’être formés ?",
          desc: "Vos commerciaux se forment facilement à tout moment grâce à nos formations en ligne pour augmenter leurs compétences et accroître vos ventes.",
        },
        {
          id: 3,
          img: require("@/assets/icons/target.svg"),
          title: "Coaching",
          subtitle: "Vous voulez que vos commerciaux soient accompagnés ?",
          desc: "Nos consultants experts en management de force de vente accompagnent vos commerciaux pour accroître leur performance.",
        },
        {
          id: 4,
          img: require("@/assets/icons/presentation-chart.svg"),
          title: "Stratégie",
          subtitle:
            "Vous souhaitez créer ou optimiser votre stratégie commerciale?",
          desc: "Nos consultants experts en stratégie de vente analysent votre stratégie commerciale et vous conseillent dans son optimisation et son déroulé opérationnel.",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.home-services {
  width: 100%;
  padding: 96px 0;
  background: $neutral25;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .home-services-cnt {
    width: $desktopContainer;
    margin: 0 auto;
    text-align: center;

    position: relative;

    .background-cnt {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;

      background-image: url("@/assets/bg-elipse.svg");
      background-repeat: no-repeat;
      background-position: center center;

      animation:spin 3s linear infinite;
    }

    .items-cnt {
      display: block;
      width: 100%;
      max-width: 1280px;
      margin: 0 auto;
      padding: 0 16px;

      position: relative;
      z-index: 1;
    }

    .service-item {
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 64px;
      }

      &:nth-child(2n) {
        float: right;
      }

      &:nth-child(2n + 1) {
        float: left;
      }
    }

    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-services {
    padding: 64px 24px;
  
    .home-services-cnt {
      width: 100%;
      
      .background-cnt {
        display: none;
      }

      .items-cnt {
        padding: 0;
        
        .service-item {
          margin-bottom: 98px;
          float: none;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>