<template>
  <section class="home-modules">
    <div class="home-modules-cnt">
      <h2 class="headline-2 fbold">
        <span class="gradient">Comment</span>
        travailler sa performance commerciale avec Onlysales ?
      </h2>
      <div class="body-lg desc">
        Quelle que soit votre activité, le succès commence par la recherche de
        la performance commerciale en choisissant les bonnes solutions.
      </div>

      <div class="items-cnt">
        <ModuleItem
          v-for="module in modules"
          :key="module.id"
          :module="module"
        />
      </div>

      <h3 class="headline-4 fbold">Bientôt disponible</h3>
      <div class="splitter">
        <img src="@/assets/splitter-dots.svg" alt="Splitter" />
      </div>

      <div class="items-cnt disabled-items-cnt">
        <ModuleItem
          v-for="module in disabledModules"
          :key="module.id"
          :module="module"
        />
      </div>
    </div>
  </section>
</template>

<script>
import ModuleItem from "@/components/atoms/ModuleItem.vue";

export default {
  name: "HomeModules",
  components: {
    ModuleItem,
  },
  data() {
    return {
      modules: [
        {
          id: 1,
          logo: require("@/assets/icons/os-jobs-white.svg"),
          disabled: false,
          title: "Jobs",
          desc: "Le job board de référence pour le recrutement de profils commerciaux B2B.",
          buttonText: "Poster ou consulter une offre",
          link: "https://jobs.onlysales.fr",
        },
        {
          id: 2,
          logo: require("@/assets/icons/os-academy-white.svg"),
          disabled: false,
          title: "Academy",
          desc: "La plateforme de formation 100% dédiée aux commerciaux dans l'IT, les services et l'industrie.",
          buttonText: "Découvrir l'offre de formation",
          link: "https://onlysalesacademy.fr",
        },
        {
          id: 3,
          logo: require("@/assets/icons/os-profiles-white.svg"),
          disabled: false,
          title: "Profiles",
          desc: "L'annuaire des commerciaux le plus fourni et la plus qualifié en France.",
          buttonText: "Parcourir les profils",
          link: "https://profiles.onlysales.fr",
        },
        {
          id: 4,
          logo: require("@/assets/icons/os-tracker-white.svg"),
          disabled: false,
          title: "Tracker",
          desc: "Un service de chasseurs de tête 100% spécialisés dans le recrutement de profils commerciaux B2B.",
          buttonText: "Recruter un commercial en 4 semaines",
          link: "https://tracker.onlysales.fr",
        },
      ],
      disabledModules: [
        {
          id: 5,
          logo: require("@/assets/icons/os-deals-white.svg"),
          disabled: true,
          title: "Deals",
          desc: "La communauté qui révolutionne l'échange de leads et d'opportunités pour les commerciaux B2B.",
          // link: "https://deals.onlysales.fr",
        },
        {
          id: 6,
          logo: require("@/assets/icons/os-tools-white.svg"),
          disabled: true,
          title: "Tools",
          desc: "Le portail d'évaluation des meilleurs outils pour optimiser sa performance commerciale.",
          // link: "https://tools.onlysales.fr",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.home-modules {
  background: white;
  width: 100%;
  padding: 96px 0;

  .home-modules-cnt {
    width: 800px;
    margin: 0 auto;

    text-align: center;

    .headline-2 {
      color: $neutral900;

      .gradient {
        background: var(
          --Gradient,
          linear-gradient(90deg, #ffae20 0%, #ef5a3c 73.44%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        text-align: center;
        font-family: "Story Script";
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 48px; /* 120% */

        padding: 0 2px;
      }
    }

    .headline-4 {
      margin: 128px 0 20px;
    }

    .splitter {
      margin-bottom: 64px;
    }

    .desc {
      color: $neutral600;
      margin-bottom: 64px;
    }

    .items-cnt {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .module-item:nth-child(1),
      .module-item:nth-child(2) {
        margin-bottom: 32px;
      }
    }

    .disabled-items-cnt {
      opacity: 0.5;
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-modules {
    padding: 64px 0;

    .home-modules-cnt {
      width: 100%;
      padding: 0 24px;

      .headline-2 {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 125% */

        margin-top: 0px;
        margin-bottom: 20px;
      }

      .headline-4 {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 133% */

        margin: 64px 0 20px;
      }

      .splitter {
        margin-bottom: 32px;
      }

      .desc {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }

      .items-cnt {
        flex-direction: column;
        align-items: center;

        .module-item {
          margin-bottom: 32px;
        }
      }
    }
  }
}
</style>