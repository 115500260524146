<template>
  <div class="home">
    <HomeHero/>
    <HomeSocialProof/>
    <HomeCommitment/>
    <HomeServices/>
    <HomeCtaBanner
      overline="Overline"
      title="Et vous où en êtes-vous de votre performance commerciale ?"
      description="This is a lorem ipsum text."
      ctaLink="https://meetings-eu1.hubspot.com/arnaud-de-onlysales/rv-avec-arnaud"
      ctaText="Parlons-en"
      :picture="ctaPic1"
    />
    <HomeMetrics/>
    <HomeModules/>
    <HomePerks/>
    <!-- <HomeTestimonials/> -->
    <HomeCtaBanner 
      overline="Overline"
      title="Analysez votre performance avec nos experts !"
      description="This is a lorem ipsum text."
      ctaLink="https://meetings-eu1.hubspot.com/arnaud-de-onlysales/rv-avec-arnaud"
      ctaText="Parlons-en"
      :picture="ctaPic2"
    />
  </div>
</template>

<script>
import HomeCommitment from '@/components/sections/HomeCommitment.vue'
import HomeHero from '@/components/sections/HomeHero.vue'
import HomeMetrics from '@/components/sections/HomeMetrics.vue'
import HomeModules from '@/components/sections/HomeModules.vue'
import HomePerks from '@/components/sections/HomePerks.vue'
import HomeServices from '@/components/sections/HomeServices.vue'
import HomeSocialProof from '@/components/sections/HomeSocialProof.vue'
import HomeCtaBanner from '@/components/sections/HomeCtaBanner.vue'
// import HomeTestimonials from '@/components/sections/HomeTestimonials.vue'

import ctaPic1 from '@/assets/cta-1.png'
import ctaPic2 from '@/assets/cta-2.png'

export default {
  name: 'HomeView',
  components: {
    HomeCommitment,
    HomeHero,
    HomeMetrics,
    HomeModules,
    HomePerks,
    HomeServices,
    HomeSocialProof,
    HomeCtaBanner,
    // HomeTestimonials,
  },
  data() {
    return {
      ctaPic1,
      ctaPic2,
    }
  }
}
</script>
