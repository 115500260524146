<template>
  <section class="home-perks">
    <div class="home-perks-cnt">
      <div class="left">
        <h2 class="headline-2 fbold">
          Pourquoi choisir Onlysales pour sa performance commerciale ?
        </h2>
        <div class="perk-pic">
          <img :src="PerkLady" alt="Why Onlysales ?" />
          <!-- Té, en voilà une question qu'elle est bonne ! -->
        </div>
      </div>
      <div class="right">
        <PerkItem :picture="medalIcon">
          <div>
            Un partenaire <strong>100% spécialisé</strong> dans la performance
            commerciale.
          </div>
        </PerkItem>

        <PerkItem :picture="briefcaseIcon">
          <div>Plus de <strong>25 ans</strong> d’expérience de la vente B2B.</div>
        </PerkItem>

        <PerkItem :picture="certificateIcon">
          <div>
            Une agence créée par des <strong>experts</strong> de la vente et du
            management.
          </div>
        </PerkItem>

        <PerkItem :picture="gridIcon">
          <div>
            Une solution tout en un pour
            <strong>augmenter vos ventes</strong> (recrutement, formation,
            coaching, conseil).
          </div>
          </PerkItem
        >

        <PerkItem :picture="settingsIcon">
          <div>
            La garantie de bénéficier des
            <strong>meilleures méthodes</strong> commerciales.
          </div>
        </PerkItem>

        <PerkItem :picture="coinsIcon">
          <div>Des <strong>tarifs compétitifs</strong> sans engagement.</div>
        </PerkItem>

        <div class="perk-pic-mobile mobile">
          <img :src="PerkLadyMobile" alt="Why Onlysales ?" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PerkLady from "@/assets/why-onlysales-lady.png";
import PerkLadyMobile from "@/assets/why-onlysales-lady-mobile.png";
import PerkItem from "@/components/atoms/PerkItem.vue";

import briefcaseIcon from "@/assets/icons/briefcase.svg";
import certificateIcon from "@/assets/icons/certificate.svg";
import coinsIcon from "@/assets/icons/coins.svg";
import gridIcon from "@/assets/icons/grid.svg";
import medalIcon from "@/assets/icons/medal.svg";
import settingsIcon from "@/assets/icons/settings.svg";

export default {
  name: "HomePerks",
  components: {
    PerkItem,
  },
  data() {
    return {
      PerkLady,
      PerkLadyMobile,

      briefcaseIcon,
      certificateIcon,
      coinsIcon,
      gridIcon,
      medalIcon,
      settingsIcon,
    };
  },
};
</script>

<style lang="scss">
.home-perks {
  width: 100%;
  padding: 96px 0;
  background: $neutral25;

  .home-perks-cnt {
    width: $desktopContainer;
    margin: 0 auto;

    .left {
      width: 592px;
      float: left;

      .headline-2 {
        margin-top: 0;
        max-width: 500px;
        margin-bottom: 48px;
      }
    }

    .right {
      width: 592px;
      float: right;
      font-size: 0;

      margin-bottom: -70px;

      .perk-item:nth-child(odd) {
        margin-right: 32px;
      }
    }

    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-perks {
    padding: 64px 24px;

    .home-perks-cnt {
      width: 100%;

      .left,
      .right {
        width: 100%;
        float: none;
      }

      .left {
        text-align: center;

        .perk-pic {
          display: none;
        }

        h2 {
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 40px; /* 125% */

          margin-bottom: 64px;
        }
      }

      .right {
        .perk-pic-mobile {
          display: block;
          width: 100%;
          margin: 0 auto;
          margin-bottom: 48px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>