<template>
  <section class="home-metrics">
    <div class="home-metrics-cnt">
      <h2 class="headline-2 fbold">
        <span class="gradient">Pourquoi</span> travailler sa performance
        commerciale ?
      </h2>
      <div class="body-lg">Les chiffres parlent d'eux-mêmes!</div>

      <div class="home-metrics-items">
        <MetricItem
          v-for="metric in metrics"
          :key="metric.title"
          :metric="metric"
        />
      </div>
    </div>
  </section>
</template>

<script>
import MetricItem from "@/components/atoms/MetricItem.vue";

export default {
  components: {
    MetricItem,
  },
  data() {
    return {
      metrics: [
        {
          percent: "90",
          title: "Des dirigeants",
          desc: "Attribuent une importance majeure à la fonction commerciale pour leur entreprise.",
        },
        {
          percent: "12",
          title: "Seulement",
          desc: "Des entreprises forment leur commerciaux pour développer des méthodes de prospection formalisées.",
        },
        {
          percent: "62",
          title: "Des acheteurs",
          desc: "Souhaitent être approchés par des fournisseurs lorsqu'ils recherchent activement de nouvelles solutions.",
        },
        {
          percent: "75",
          title: "Des commerciaux",
          desc: "Pensent que leur approche les différencie de leurs concurrents, alors que seuls 3% des clients le confirment.",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.home-metrics {
  display: block;
  width: 100%;
  padding: 80px 0;

  background: $neutral25;
  text-align: center;

  position: relative;

  background-image: url("@/assets/bg-line.svg");
  background-size: 1453px 501px;
  background-repeat: no-repeat;
  background-position: center;
  // a bit lower
  background-position-y: 300px;

  .background-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .home-metrics-cnt {
    display: block;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    h2 {
      width: 800px;
      margin: 0 auto 20px;

      .gradient {
        text-align: center;
        font-family: "Story Script";
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 48px; /* 120% */

        background: linear-gradient(90deg, #ffae20 0%, #ef5a3c 73.44%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        padding-right: 4px;
      }
    }

    .home-metrics-items {
      width: 800px;
      margin: 64px auto 0;

      .metric-item {
        float: left;
        z-index: 1;

        &:nth-child(2n) {
          float: right;
        }

        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 32px;
        }

        &:nth-child(2) {
          margin-top: 64px;
        }
      }

      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-metrics {
    padding: 64px 24px;
    background-image: none;

    .home-metrics-cnt {
      h2 {
        width: 100%;
        margin: 0 auto 16px;
      }

      .home-metrics-items {
        width: 100%;
        margin: 64px auto 0;

        .metric-item {
          float: none!important;
          margin: 32px auto!important;

          &:last-child {
            margin-bottom: 0!important;
          }
        }
      }
    }
  }
}
</style>