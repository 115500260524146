<template>
  <div class="cta-item">
    <div class="cta-item-cnt">
      <div class="cta-item-txt">
        <div class="cta-item-overline uppercase-sm fbold">{{ overline }}</div>
        <h2 class="cta-item-title headline-3 fbold">{{ title }}</h2>
        <p class="cta-item-description body-lg">{{ description }}</p>
        <CtaButton
          :label="ctaText"
          @click="onCtaClick"
          :icon="ExtLinkIcon"
        />
      </div>

      <div class="cta-item-picture">
        <img :src="picture" alt="Picture" />
      </div>
    </div>
  </div>
</template>

<script>
import CtaButton from "front-lib-os/src/components/atoms/CtaButton.vue";
import ExtLinkIcon from 'front-lib-os/src/assets/ext-link.svg'

export default {
  name: 'HomeCtaBanner',
  components: {
    CtaButton,
  },
  props: {
    overline: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    picture: {
      type: String,
      required: true,
    },
    ctaText: {
      type: String,
      required: true,
    },
    ctaLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ExtLinkIcon,
    }
  },
  methods: {
    onCtaClick() {
      window.open(this.ctaLink);
    },
  },
}
</script>

<style lang="scss">
.cta-item {
  background: $neutral25;
  width: 100%;
  padding: 96px 0;

  .cta-item-cnt {
    background: $neutral900;
    color: $neutral25;
    width: $desktopContainer;
    height: 440px;
    margin: 0 auto;
    padding-left: 64px;
    border-radius: 16px;

    .cta-item-txt {
      float: left;
      width: 544px;

      .cta-item-overline {
        margin-top: 80px;
        margin-bottom: 16px;
        visibility: hidden;
      }

      .cta-item-title {
        margin-bottom: 24px;
        color: $neutral25;
      }

      .cta-item-description {
        margin-bottom: 40px;
        visibility: hidden;
      }

      .cstm-button button {
        border: none;
      }

    }

    .cta-item-picture {
      width: 544px;
      height: 440px;
      position: relative;
      float: right;

      img {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .cta-item {
    padding: 64px 24px;

    .cta-item-cnt {
      width: 100%;
      height: auto;
      padding: 0;

      .cta-item-txt {
        width: 100%;
        float: none;
        text-align: center;
        padding: 40px 24px 0;

        .cta-item-overline {
          margin-top: 12px;
          margin-bottom: 16px;
        }

        .cta-item-title {
          margin-bottom: 24px;

          font-size: 24px;
          font-weight: 700;
          line-height: 32px;

          // todo: remove
          margin-top: -40px;
          margin-bottom: -70px;
        }

        .cta-item-description {
          margin-top: 40px;
          margin-bottom: 62px;
        }

        .cstm-button button img {
          position: relative;
          top: 2px;
        }
      }

      .cta-item-picture {
        width: 100%;
        height: auto;
        position: relative;
        float: none;
        font-size: 0;

        img {
          width: 100%;
          position: relative;
          bottom: -11px;
          right: 0;
        }
      }
    }
  }
}
</style>