<template>
  <div class="metric-item">
    <div class="metric-item-cnt">
      <div class="metric-item-percent headline-3 fbold">{{ metric.percent }}
        <span class="percent-sign">%</span>
      </div>
      <div class="metric-item-title body-lg fsemibold">{{ metric.title }}</div>
      <div class="metric-item-desc body-md">{{ metric.desc }}</div>
      <!-- <div class="metric-item-btn">
        <button class="btn btn-primary">En savoir plus</button>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    metric: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.metric-item {
  display: block;
  width: 384px;
  height: 246px;
  padding: 32px 24px;

  border-radius: 16px;
  border: 1px solid $neutral100;
  background: white;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  text-align: center;

  .metric-item-cnt {
    width: 100%;
    text-align: center;

    .metric-item-percent {
      color: $primary400;
      text-align: center;
      font-family: "Story Script";
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 56px;

      .percent-sign {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .metric-item-title {
      margin: 12px 0 8px;
    }
  }
}

@media (max-width: 768px) {
  .metric-item {
    width: 100%;
    height: auto;
    padding: 32px 24px;
  }
}
</style>