<template>
  <div id="app">
    <AppHeader :logo="logo" />
    <router-view/>
    <AppFooter/>
  </div>
</template>

<script>
import AppHeader from 'front-lib-os/src/components/AppHeader.vue'
import AppFooter from 'front-lib-os/src/components/AppFooter.vue'

import logo from 'front-lib-os/src/assets/onlysales-logo.svg'
import logoWhite from 'front-lib-os/src/assets/onlysales-logo-white.svg'
import ExtLinkIcon from 'front-lib-os/src/assets/ext-link.svg'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
  },
  data () {
    return {
      logo,
      logoWhite,
      extLinkIcon: ExtLinkIcon
    }
  },
  methods: {
    onClickHelpMeRecruit() {
      window.open("https://meetings-eu1.hubspot.com/arnaud-de-onlysales/rv-avec-arnaud");
    },
  }
}
</script>

<style lang="scss">
body {
  color: $neutral900;

  .jobs-link {
    color: $neutral900;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    margin-right: 22px;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  body {
    width: 100vw;
    overflow-x: hidden;
  }
}
</style>
