<template>
  <section class="home-social-proof">
    <div class="home-social-proof-cnt">
      <div class="headline-5">Ils nous font confiance</div>
      <div class="desktop">
        <div class="slider">
          <div class="slide-track">
            <img
              v-for="(client, index) in picsLine1"
              :key="index"
              class="slide"
              :src="client.pic"
              :alt="client.name"
            />
          </div>
        </div>
      </div>
      <div class="mobile">
        <div class="slider">
          <div class="slide-track">
            <img
              v-for="(client, index) in picsLine1"
              :key="index"
              class="slide"
              :src="client.pic"
              :alt="client.name"
            />
          </div>
        </div>
        <div class="slider">
          <div class="slide-track">
            <img
              v-for="(client, index) in picsLine2"
              :key="index"
              class="slide"
              :src="client.pic"
              :alt="client.name"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import logo_1 from "@/assets/logos/logo_1.svg";
import logo_2 from "@/assets/logos/logo_2.svg";
import logo_3 from "@/assets/logos/logo_3.svg";
import logo_4 from "@/assets/logos/logo_4.svg";
import logo_5 from "@/assets/logos/logo_5.svg";
import logo_6 from "@/assets/logos/logo_6.svg";
import logo_7 from "@/assets/logos/logo_7.png";
import logo_8 from "@/assets/logos/logo_8.svg";

export default {
  data() {
    return {
      picsLine1: [
        { pic: logo_1, name: "web-atrio" },
        { pic: logo_2, name: "aosis" },
        { pic: logo_3, name: "sii" },
        { pic: logo_4, name: "prime" },
        { pic: logo_5, name: "keyrus" },
        { pic: logo_6, name: "easiware" },
        { pic: logo_7, name: "puy du fou" },
        { pic: logo_8, name: "se loger" },
        { pic: logo_1, name: "web-atrio" },
        { pic: logo_2, name: "aosis" },
        { pic: logo_3, name: "sii" },
        { pic: logo_4, name: "prime" },
        { pic: logo_5, name: "keyrus" },
        { pic: logo_6, name: "easiware" },
        { pic: logo_7, name: "puy du fou" },
        { pic: logo_8, name: "se loger" },
      ],
      picsLine2: [
        { pic: logo_5, name: "keyrus" },
        { pic: logo_6, name: "easiware" },
        { pic: logo_7, name: "puy du fou" },
        { pic: logo_8, name: "se loger" },
        { pic: logo_1, name: "web-atrio" },
        { pic: logo_2, name: "aosis" },
        { pic: logo_3, name: "sii" },
        { pic: logo_4, name: "prime" },
        { pic: logo_5, name: "keyrus" },
        { pic: logo_6, name: "easiware" },
        { pic: logo_7, name: "puy du fou" },
        { pic: logo_8, name: "se loger" },
        { pic: logo_1, name: "web-atrio" },
        { pic: logo_2, name: "aosis" },
        { pic: logo_3, name: "sii" },
        { pic: logo_4, name: "prime" },
      ],
    };
  },
};
</script>

<style lang="scss">
.home-social-proof {
  width: 100%;
  padding: 96px 0 32px;
  background: $neutral25;

  .home-social-proof-cnt {
    width: $desktopContainer;
    margin: 0 auto;
    text-align: center;

    .headline-5 {
      text-align: center;
      color: $neutral600;
      margin-bottom: 28px;
    }

    // Animation
    .slider {
      height: 60px;
      position: relative;
      width: 100%;

      display: grid;
      place-items: center;
      overflow: hidden;
    }
    .slider::before,
    .slider::after {
      position: absolute;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      content: "";
      height: 100%;
      width: 25%;
      z-index: 2;
      pointer-events: none;
    }
    .slider::before {
      left: 0;
      top: 0;
    }
    .slider::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }

    .slide-track {
      width: calc(150px * 20);
      display: flex;
      animation: scroll 20s linear infinite;
      justify-content: space-between;
    }

    img {
      height: 48px;
    }

    @keyframes scroll {
      0% {
        transform: translateX(0px);
      }
      100% {
        transform: translateX(calc(-150px * 10));
      }
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .home-social-proof {
    padding: 64px 24px 32px;

    .home-social-proof-cnt {
      width: 100%;

      .headline-5 {
        margin-bottom: 24px;
      }
    }
  }
}

</style>