<template>
  <div class="module-item" :class="{ disabled: module.disabled }">
    <div class="module-item-cnt">
      <div class="top-part">
        <div class="module-item-logo">
          <img :src="module.logo" alt="logo" />
        </div>
        <div class="module-item-title body-lg fsemibold">
          {{ module.title }}
        </div>
        <div class="module-item-desc body-sm">{{ module.desc }}</div>
      </div>
      <div class="button-part">
        <div class="module-item-btn">
          <span v-if="module.disabled" class="body-md fsemibold">
            Bientôt disponible
          </span>
          <a v-else :href="module.link" target="_blank" class="body-md fsemibold">
            <span style="margin-right: 8px">{{module.buttonText}}</span>&gt;</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    module: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.module-item {
  width: 384px;

  border-radius: 12px;
  border: 1px solid $neutral200;
  background: white;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  .module-item-cnt {
    font-size: 0;
    text-align: left;

    .top-part {
      padding: 24px;

      .module-item-logo {
        display: inline-block;
        width: 42px;
        height: 42px;
        padding: 9px;

        border-radius: 8px;
        background: linear-gradient(225deg, #ffae20 0%, #ef5a3c 73.44%);

        img {
          width: 24px;
          height: 24px;
        }
      }

      .module-item-title {
        margin-left: 15px;
        color: $neutral900;
        display: inline-block;
        line-height: 42px;
        vertical-align: top;
      }

      .module-item-desc {
        margin-top: 24px;
      }
    }

    .button-part {
      border-top: 1px solid $neutral200;
      padding: 16px 24px;

      a {
        text-decoration: none;
        color: $neutral900;

        display: inline-block;
        padding: 0 4px 4px;

        border-bottom: 2px solid #d0d5dd;
      }
    }
  }

  &.disabled {
    .top-part {
      .module-item-logo {
        background: linear-gradient(45deg, #101828 0%, #d0d5dd 100%);
      }
    }
  }
}

@media (max-width: 768px) {
  .module-item {
    width: 100%;
    height: auto;
  }
}
</style>  