<template>
  <div class="perk-item">
    <div class="perk-item-picture">
      <div class="perk-item-picture-cnt">
        <img :src="picture" alt="Picture" />
      </div>
    </div>
    <div class="perk-item-txt">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    picture: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.perk-item {
  width: 280px;
  height: 215px;
  float: left;
  font-size: 1rem;

  .perk-item-txt {
    height: 60px;
  }

  .perk-item-picture {
    margin-bottom: 48px;

    .perk-item-picture-cnt {
      width: 56px;
      height: 56px;
      padding: 10.84px 10.65px 11.22px 11.41px;
      border-radius: 12px;
      transform: rotate(-45deg);
      background: $primary50;
      text-align: center;

      img {
        margin: 5px;
        rotate: 45deg;
        width: 24px;
        height: 24px;
      }
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .perk-item {
    width: 100%;
    margin-bottom: 48px;
    text-align: center;
    width: 100%;
    height: auto;

    .perk-item-picture {
      .perk-item-picture-cnt {
        margin: 0 auto;
      }
    }

    .perk-item-txt {
      max-width: 280px;
      margin: 0 auto;
    }
  }
}
</style>